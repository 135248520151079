import { NavigationClient, NavigationOptions } from "@azure/msal-browser";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import en from "./translations/en.json";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        formHeader: {
            color:'#00376a'
        },
        checkboxLabel: {
            color:"#8CC63F"
        }
    }),
);

export const isInteractionRequired = (error: Error): boolean => {
    if (!error.message || error.message.length <= 0) {
        return false;
    }

    return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
    );
}

export function literals(): object {
    return en as { [key: string]: string };
}

export class CustomNavigationClient extends NavigationClient {
    private history: any;

    constructor(history: any) {
        super();
        this.history = history;
    }

    /**
     * Navigates to other pages within the same web application
     * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
     * @param url 
     * @param options 
     */
    async navigateInternal(url: string, options: NavigationOptions) {
        const relativePath = url.replace(window.location.origin, '');
        if (options.noHistory) {
            this.history.replace(relativePath);
        } else {
            this.history.push(relativePath);
        }

        return false;
    }
}