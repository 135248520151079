import React from 'react';
import { useHistory,Route } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { ColorButton } from './ColorButton';
import { useTranslation } from 'react-i18next';
import { AnonymousHome } from './AnonymousHome';
import { useStyles } from '../Utils';
import { loginRequest } from '../authConfig';
import { Verification } from './Verification';

export const Landing = () => {
    const history = useHistory();
    const { instance } = useMsal();
    const login = () => {
        instance.loginRedirect(loginRequest);
    }
    const { t } = useTranslation('common');
    const classes = useStyles();
    return <div className={classes.root}>
        <Route path="/" exact={true}>
            <br />
            <br />
            <ColorButton variant="contained" color="primary" onClick={() => { login() }}>{t('knowMyLoginInfo')}</ColorButton>
            <br />
            <br />
            <ColorButton variant="contained" color="primary" onClick={() => { history.push('/anonymous') }}>{t('forgotMyCredentials')}</ColorButton >
        </Route>
        <Route path="/anonymous" exact={true} component={AnonymousHome} />
    </div>;
}