import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppContextWrapper from './AppContext';
import { msalConfig } from './authConfig';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import en from "./translations/en.json";
import es from "./translations/es.json";
import LanguageDetector from 'i18next-browser-languagedetector';
import { Layout } from './components/Layout';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')?.toString();
const rootElement = document.getElementById('root');
const msalInstance = new PublicClientApplication(msalConfig);
//const accounts = msalInstance.getAllAccounts();
//if (accounts.length > 0) {
//    msalInstance.setActiveAccount(accounts[0]);
//}

i18next
    .use(LanguageDetector)
    .init({
        interpolation: { escapeValue: false },
        fallbackLng: 'en',
        resources: {
            en: {
                common: en
            },
            es: {
                common: es
            }
        }
    });

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <I18nextProvider i18n={i18next}>
            <Layout>
                <AppContextWrapper msalInstance={msalInstance} />
            </Layout>
        </I18nextProvider>
    </BrowserRouter>,
    rootElement);

