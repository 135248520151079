import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorButton } from './ColorButton'
import Button from '@material-ui/core/Button';

export const FileUploader = (props: { data: string | undefined, onDataChanged: (newData: string | undefined, fileName: string | undefined, fileType: string | undefined) => void }) => {
    const { t } = useTranslation('common');
    const fileRef = useRef<HTMLInputElement>(null);

    const fileChanged = (files: FileList | null) => {
        if (files) {
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                props.onDataChanged(reader.result?.toString(), file.name, file.type);
            }
        }
    }
    const text = props.data ? t("replaceFile") : t("chooseFile")

    return <>
        {props.data && <img src={props.data} style={{ width: "95%" }} />}
        <div>
            {props.data && <> <Button variant="contained" color="secondary" onClick={() => props.onDataChanged(undefined, undefined, undefined)}>{t("removeImage")}</Button><span>&nbsp;&nbsp;&nbsp;</span></>}
            <ColorButton variant="contained" color="primary" onClick={() => fileRef.current?.click()}>{text}</ColorButton>
        </div>
        <div style={{ display: "none" }}>
            <input type="file" ref={fileRef} multiple={false} onChange={(e) => fileChanged(e.target.files)} />
        </div>
    </>
}