import React, { createRef, useContext, useEffect, useState } from 'react';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { useStyles } from '../Utils';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { AppContext } from '../AppContext';
import { VaccinationRecord } from '../generated/ClientApi';
import { ColorButton } from './ColorButton';
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, Typography } from '@material-ui/core';


const VerificationView = (props: { record: VaccinationRecord, onSubmit: (res: boolean) => void }) => {
    const ref = createRef<HTMLIFrameElement>();
    const style = { width: "100%", height: "600px" }
    const getDocumentViewer = () => {
        switch (props.record.documentType) {
            case 'image/jpeg':
            case 'image/png':
                return (<img style={style} src={`/api/anonymous/GetRecordPicture?recordId=${props.record.recordId}&type=vaccine`} />);
            case 'application/pdf':
                setTimeout(() => {
                    if (ref.current)
                        ref.current.src = `/api/anonymous/GetRecordPicture?recordId=${props.record.recordId}&type=vaccine`;
                }, 100);
                return (<iframe ref={ref} style={style}></iframe>);
        }
    }

    return <>
        <h1><Typography>{props.record.employeeName} {props.record.employeeNoStr}, {props.record.employeeInfo?.empCompany}.{props.record.employeeInfo?.empDivision}.{props.record.employeeInfo?.empNo}, {props.record.employeeInfo?.class}.{props.record.employeeInfo?.subClass} - {props.record.employeeInfo?.classDescription}</Typography></h1>
        <p><Typography>Submitted: {new Date(props.record.submitted).toLocaleDateString()}</Typography></p>
        {!props.record.uploaded && <p><Typography>Something is wrong with the file</Typography><span>{props.record.documentUrl}</span></p>}
        <a target="_blank" href={`/api/anonymous/GetRecordPicture?recordId=${props.record.recordId}&type=vaccine`}>DOWNLOAD</a>
        {getDocumentViewer()}
        <div>
            <ColorButton variant="contained" color="primary" onClick={() => props.onSubmit(true)}>Valid</ColorButton>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <Button variant="contained" color="secondary" onClick={() => props.onSubmit(false)}>Not Valid</Button>
        </div>

    </>
}

export const Verification = () => {
    const { instance } = useMsal();
    const classes = useStyles();
    const ctx = useContext(AppContext);
    const [state, setState] = useState<VaccinationRecord | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const authRequest = {
        ...loginRequest
    };

    const getNewRecord = async (rec: VaccinationRecord) => {
        setLoading(true);
        try {
            const record = await ctx.apiMap?.mainClient.submitVerification(rec);
            setState(record);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    const onSubmit = async (res: boolean) => {
        if (state) {
            state.verified = res;
            getNewRecord(state);
        }            
    }

    useEffect(() => {
        getNewRecord({} as VaccinationRecord);
    }, []);

    return <div className={classes.root}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            {state && <VerificationView record={state} onSubmit={onSubmit} />}
            {error && <p>{ error }</p>}
            {loading && <Dialog
                open={loading}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText><CircularProgress /><br />Loading</DialogContentText>
                </DialogContent>
            </Dialog>}
        </MsalAuthenticationTemplate>
    </div>;
}