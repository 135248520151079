import React, { ReactNode } from 'react';
import { Container } from '@material-ui/core';
import { NavMenu } from './NavMenu';
import { esES, enUS } from '@material-ui/core/locale';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { createTheme, ThemeProvider, useTheme } from '@material-ui/core/styles';
const locales = {
    'en': enUS,
    'es': esES
}

export const Layout = ({ children }: { children: ReactNode }) => {
    const [locale, setLocale] = React.useState<'en' | 'es'>('en');
    const theme = useTheme();
    return (
        <div>
            <NavMenu setLocale={setLocale} />
            <ThemeProvider theme={theme}>
                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                    <Container>
                        <div>{children}</div>
                    </Container>
                </ThemeProvider>
            </ThemeProvider>
        </div>
    );
}
