import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { VaccinationForm } from './VaccinationForm';
import { ActionMenu } from './ActionMenu';
import { TestingForm } from './TestingForm';
import { Done } from './Done';
import { AppContext } from '../AppContext';
import { VVSAction } from '../generated/ClientApi';

export const AuthUsersHome = () => {
    const ctx = useContext(AppContext);
    const [user, setUser] = useState(undefined);
    const [action, setAction] = useState<VVSAction | undefined>(undefined);
    const init = async () => {
        const [u, a] = await Promise.all([ctx.apiMap?.getUser(), ctx.apiMap?.mainClient.getAction()]);
        setUser(u);
        setAction(a);
    }
    useEffect(() => {
        init();
    }, [])
    if (action == VVSAction.UpdateVaccinationForm) {
        return <VaccinationForm user={user} setAction={setAction}/>
    } else if (action == VVSAction.ShowUpdateMenu) {
        return <ActionMenu setAction={setAction} />
    } else if (action == VVSAction.UpdateTestingForm) {
        return <TestingForm user={user} setAction={setAction}/>
    } else if (action == VVSAction.Done) {
        return <Done setAction={setAction}/>
    } else {
        return <></>;
    }
}