import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { AppContext } from '../AppContext';
import { VVSAction } from '../generated/ClientApi';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ColorButton } from './ColorButton';
import { useHistory } from 'react-router';

const validUsers = ["dmytro.doroshkevych@mcdean.com","david.beatty@mcdean.com"]

export const Done = ({ setAction }: { setAction: (v: VVSAction) => void}) => {
    const { t } = useTranslation('common');
    const ctx = useContext(AppContext);
    const isAuthenticated = useIsAuthenticated();
    const history = useHistory();
    const { instance } = useMsal();
    const handleLogout = () => {
        instance.logoutPopup();
    }

    const username = isAuthenticated ? instance.getAllAccounts()[0].username.toLowerCase() : '';
    const remove = async () => {
        await ctx.apiMap?.mainClient.deleteVacinationRecord();
        setAction(VVSAction.UpdateVaccinationForm);
    }
    return (<div>
        <Typography variant="h4">{t('allSet')}</Typography>
        {isAuthenticated && <>
            {/*<Button onClick={remove}>remove</Button>*/}
            <ColorButton variant="contained" color="primary" onClick={handleLogout}>{t('logOut')}</ColorButton>
        </>}
        {!isAuthenticated && <>
            <ColorButton variant="contained" color="primary" onClick={() => { history.push('/') }}>{t('goBack')}</ColorButton>
        </>}
        {validUsers.find(a => a.toLowerCase() == username) && <ColorButton variant="contained" color="primary" onClick={() => { history.push('/verify-vaccination-record') }}>Verify Vaccination Records</ColorButton>}
    </div>);
}