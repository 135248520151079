import React, { useState, createContext, useEffect } from 'react';
import { ClientApiMap } from './ClientApiMap';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { useHistory } from 'react-router';
import { CustomNavigationClient, isInteractionRequired } from './Utils';
import { loginRequest } from './authConfig';
import { App } from './App';
import { Landing } from './components/Landing';

export interface AppContextState {

}

export interface AppContextValue {
    apiMap?: ClientApiMap;
}

export const AppContext = createContext<AppContextValue>({});

export default function AppContextWrapper(props: { msalInstance: IPublicClientApplication}) {
    const { inProgress } = useMsal();
    const getAccessToken = async (scopes: string[]): Promise<string> => {
        if (inProgress === InteractionStatus.None && props.msalInstance) {
            try {
                const accounts = props.msalInstance
                    .getAllAccounts();
                if (accounts.length <= 0) throw new Error('login_required');
                var silentResult = await props.msalInstance
                    .acquireTokenSilent({
                        scopes: scopes,
                        account: accounts[0]
                    });
                return silentResult.accessToken;
            } catch (err) {
                if (isInteractionRequired(err)) {
                    await props.msalInstance.acquireTokenRedirect({ scopes: scopes });
                    return "";
                } else {
                    throw err;
                }
            }
        } else {
            return "";
        }
    }

    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    props.msalInstance?.setNavigationClient(navigationClient);
    const apiMap = new ClientApiMap(getAccessToken);

    return <AppContext.Provider value={{ apiMap }}>
        <MsalProvider instance={props.msalInstance}>
            <App />
        </MsalProvider>
    </AppContext.Provider>
}
