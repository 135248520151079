import React from 'react';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';

export const PhoneField = (props: { inputRef: (ref: HTMLInputElement | null) => void;}) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

export const EmailField = (props: { inputRef: (ref: HTMLInputElement | null) => void; }) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/^([a-zA-Z0-9_\-\.]+)/,'@',/([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/]}
            placeholderChar={'\u2000'}            
        />
    );
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const NumberField = (props: NumberFormatCustomProps) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
            prefix=""
        />
    );
}