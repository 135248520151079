import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Typography, CircularProgress, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, Input } from '@material-ui/core';
import { ColorButton } from './ColorButton';
import { FileUploader } from './FileUploader';
import { AppContext } from '../AppContext';
import { GreenCheckbox } from './GreenCheckbox'
import { VaccinationRecord, VVSAction } from '../generated/ClientApi';
import { useStyles } from '../Utils';
import { PhoneField, EmailField, NumberField } from './TextMask';


export const VaccinationForm = ({ user, setAction }: { user: any, setAction: (v: VVSAction) => void }) => {
    const { t } = useTranslation('common');
    const ctx = useContext(AppContext);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState<VaccinationRecord | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const fetchData = async () => {
        const data = user ? await ctx.apiMap?.mainClient.getVacinationRecord() : await ctx.apiMap?.anonymousClient.getEmptyVaccinationRecord();
        if (data) {
            if (user) {
                console.log(user);
                data.employeeId = user.id as string;
                data.employeeName = user.displayName as string;
                data.employeeNoStr = user.extension_41897758e7564190ac6d4f5ea4ecd9b8_extensionAttribute1 as string;
            }
            setRecord(data);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const submit = async () => {
        if (record) {
            try {
                if (!record.acknowledged) {
                    throw t('acknowledgementRequired');
                }
                if ((record.fullyVaccinated || record.partiallyVaccinated) && !record.documentUrl) {
                    throw t('attachmentRequired');
                }
                if (record.communicateByEmail && (!record.email || record.email == '')) {
                    throw t('provideEmail');
                }
                if (record.communicateByEmail && record.email && !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(record.email)) {
                    throw t('incorrectEmailFormat');
                }
                if (record.communicateByText && (!record.phone || record.phone == '')) {
                    throw t('providePhone');
                }
                if (!record.communicateByEmail && !record.communicateByText) {
                    throw t('communicationWayRequired');
                }
                if (!record.employeeName || record.employeeName == '' || !/\w{2,50}\s?\w{2,50}/.test(record.employeeName)) {
                    throw t('employeeNameIsRequired');
                }
                if (!record.employeeNoStr || record.employeeNoStr == '') {
                    throw t('employeeNoIsRequired');
                }
                if (record.employeeNoStr.length > 5 && !user) {
                    throw t('employeeNoWrongFormat');
                }
                setLoading(true);
                try {
                    await ctx.apiMap?.anonymousClient.updateVacinationRecord(record);
                    setLoading(false)
                    if ((record.notFullyVaccinated == true || record.partiallyVaccinated == true) && record.projectRequiresTestingValidation == true) {
                        setAction(VVSAction.UpdateTestingForm);
                        return;
                    }
                    setAction(VVSAction.Done);
                } catch (err) {
                    setLoading(false)
                    throw err;
                }
            } catch (err) {
                setError(err);
            }
        }
    }

    const chooseNotToDiscloseChanged = (val: boolean) => {
        const temp: VaccinationRecord = { ...record } as VaccinationRecord;
        if (temp) {
            if (val === true) {
                temp.partiallyVaccinated = false;
                temp.notFullyVaccinated = false;
                temp.fullyVaccinated = false;
            } else {
                temp.documentUrl = undefined;
                temp.documentType = undefined;
                temp.documentName = undefined;
            }
            temp.chooseNotToDisclose = val;
            setRecord(temp);
        }
    }
    const partiallyVaccinatedChanged = (val: boolean) => {
        const temp: VaccinationRecord = { ...record } as VaccinationRecord;
        if (temp) {
            if (val === true) {
                temp.fullyVaccinated = false;
                temp.notFullyVaccinated = false;
                temp.chooseNotToDisclose = false;
            } else {
                temp.documentUrl = undefined;
                temp.documentType = undefined;
                temp.documentName = undefined;
            }
            temp.partiallyVaccinated = val;
            setRecord(temp);
        }
    }
    const fullyVaccinatedChanged = (val: boolean) => {
        const temp: VaccinationRecord = { ...record } as VaccinationRecord;
        if (temp) {
            if (val === true) {
                temp.partiallyVaccinated = false;
                temp.notFullyVaccinated = false;
                temp.chooseNotToDisclose = false;
            } else {
                temp.documentUrl = undefined;
                temp.documentType = undefined;
                temp.documentName = undefined;
            }
            temp.fullyVaccinated = val;
            setRecord(temp);
        }
    }
    const notFullyVaccinatedChanged = (val: boolean) => {
        const temp: VaccinationRecord = { ...record } as VaccinationRecord;
        if (temp) {
            if (val === true) {
                temp.partiallyVaccinated = false;
                temp.fullyVaccinated = false;
                temp.chooseNotToDisclose = false;
            } else {
                temp.documentUrl = undefined;
                temp.documentType = undefined;
                temp.documentName = undefined;
            }
            temp.notFullyVaccinated = val;
            setRecord(temp);
        }
    }

    return (record ?
        <div className={classes.root}>
            <br />
            <Typography variant="h5" className={classes.formHeader}>{t('vaccineTrackingTitle')}</Typography>
            <br />
            <Typography variant="h6">{t('acknowledgment')}</Typography>
            <Typography>{t('vaccineTrackingText')}</Typography>
            <FormControlLabel
                control={
                    <GreenCheckbox
                        checked={record?.acknowledged}
                        size="medium"
                        onChange={(event: any) => { setRecord({ ...record, acknowledged: event.target.checked }) }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
                label={<b className={classes.checkboxLabel}>{t('acknowledgeLabel')}</b>}
            />
            {record.acknowledged === true && <><br />
                <Typography variant="h6">{t('confirmYourVaccineStatus')}</Typography>
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            checked={record.fullyVaccinated}
                            size="medium"
                            onChange={(event: any) => { fullyVaccinatedChanged(event.target.checked) }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={<b className={classes.checkboxLabel}>{t('fullyVaccinatedTitle')}</b>}
                />
                <Typography>{t('fullyVaccinatedDescription')}</Typography>
                <br />
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            checked={record.partiallyVaccinated}
                            size="medium"
                            onChange={(event: any) => { partiallyVaccinatedChanged(event.target.checked) }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={<b className={classes.checkboxLabel}>{t('partiallyVaccinatedTitle')}</b>}
                />
                <Typography>{t('partiallyVaccinatedDescription')}</Typography>
                <br />
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            checked={record.notFullyVaccinated}
                            size="medium"
                            onChange={(event: any) => { notFullyVaccinatedChanged(event.target.checked) }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={<b className={classes.checkboxLabel}>{t('notFullyVaccinatedTitle')}</b>}
                />
                <Typography>{t('notFullyVaccinatedDescription')}</Typography>
                <br />
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            checked={record.chooseNotToDisclose}
                            size="medium"
                            onChange={(event: any) => { chooseNotToDiscloseChanged(event.target.checked) }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={<b className={classes.checkboxLabel}>{t('chooseNotToDiscloseTitle')}</b>}
                />
                <Typography>{t('chooseNotToDiscloseDescription')}</Typography>
                <br />
                {record.notFullyVaccinated || record.partiallyVaccinated && <><FormControlLabel
                    control={
                        <GreenCheckbox
                            checked={record.projectRequiresTestingValidation}
                            size="medium"
                            onChange={(event: any) => { setRecord({ ...record, projectRequiresTestingValidation: event.target.checked }) }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={<b className={classes.checkboxLabel}>{t('projectRequiresTestingValidation')}</b>}
                />
                    <br /></>}
                {(record.fullyVaccinated || record.partiallyVaccinated) && <>
                    <Typography><b>{t("upload")}</b></Typography>
                    <FileUploader data={record.documentUrl} onDataChanged={(d, fn, ft) => { setRecord({ ...record, documentUrl: d, documentName: fn, documentType: ft }) }} />
                    <br />
                </>
                }
                <Typography><i>{t('disclimer')}</i></Typography>
                <hr />
                <Typography><b>{t('bestCommunicationWay')}</b></Typography>
                <div>
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                checked={record.communicateByEmail}
                                size="medium"
                                onChange={(event: any) => { setRecord({ ...record, communicateByEmail: event.target.checked }) }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label={t('email')}
                        style={{ width: "80px" }}
                    />
                    <Input
                        style={{ width: "200px" }}
                        value={record.email}
                        onChange={(event) => { setRecord({ ...record, email: event.target.value }) }}
                        disabled={!record.communicateByEmail}
                    />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                checked={record.communicateByText}
                                size="medium"
                                onChange={(event: any) => { setRecord({ ...record, communicateByText: event.target.checked }) }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label={t('sms')}
                        style={{ width: "80px" }}
                    />
                    <Input
                        style={{ width: "200px" }}
                        value={record.phone}
                        onChange={(event) => { setRecord({ ...record, phone: event.target.value }) }}
                        disabled={!record.communicateByText}
                        inputComponent={PhoneField as any}
                    />
                </div>
                <TextField value={record.employeeName} label={t('employeeName')} disabled={user} onChange={(event) => { setRecord({ ...record, employeeName: event.target.value }) }} />
                <TextField InputProps={{ inputComponent: NumberField as any }} value={record.employeeNoStr} label={t('employeeNumber')} disabled={user} onChange={(event) => { setRecord({ ...record, employeeNoStr: event.target.value }) }} />
                <br />

                <div>
                    <ColorButton variant="contained" color="primary" onClick={submit}>{t("submit")}</ColorButton>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button variant="contained" color="secondary" onClick={() => setAction(VVSAction.ShowUpdateMenu)}>{t("close")}</Button>
                </div>
            </>}

            <Dialog
                open={error != undefined}
                onClose={() => setError(undefined)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('errorTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{error}</DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={loading}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText><CircularProgress /><br />{t('loading')}</DialogContentText>
                </DialogContent>
            </Dialog>
        </div> : <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText><CircularProgress /><br />{t('loading')}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
