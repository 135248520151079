import React, { useState } from 'react';
import { VaccinationForm } from './VaccinationForm';
import { ActionMenu } from './ActionMenu';
import { TestingForm } from './TestingForm';
import { Done } from './Done';
import { VVSAction } from '../generated/ClientApi';

export const AnonymousHome = () => {
    const [action, setAction] = useState<VVSAction>(VVSAction.ShowUpdateMenu);
    return <>
        {action == VVSAction.UpdateVaccinationForm && <VaccinationForm user={undefined} setAction={setAction} />}
        {action == VVSAction.ShowUpdateMenu && <ActionMenu  setAction={setAction} />}
        {action == VVSAction.UpdateTestingForm && <TestingForm user={undefined} setAction={setAction} />}
        {action == VVSAction.Done && <Done setAction={setAction} />}
    </>
}