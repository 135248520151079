import { loginRequest } from './authConfig';
import { IMainClient, ApiException, MainClient, IAnonymousClient, AnonymousClient } from './generated/ClientApi';

export class ClientApiMap {
    private _getAccessToken: Function | undefined;
    private _signal: AbortSignal | undefined;
    public mainClient: IMainClient;
    public anonymousClient: IAnonymousClient;
    constructor(getAccessToken: Function | undefined) {
        this._getAccessToken = getAccessToken;
        this.mainClient = new MainClient(process.env.REACT_APP_BASE_URL, { fetch: this.fetchProxy });
        this.anonymousClient = new AnonymousClient(process.env.REACT_APP_BASE_URL, { fetch: this.fetchProxy2 });
    }
    public setSignal(s: AbortSignal | undefined) {
        this._signal = s;
    }

    public getUser = async () => {
        if (this._getAccessToken) {
            const token = await this._getAccessToken(["User.Read"]);
            return fetch("https://graph.microsoft.com/v1.0/me?$select=displayName,givenName,surname,id,extension_41897758e7564190ac6d4f5ea4ecd9b8_extensionAttribute1,extension_41897758e7564190ac6d4f5ea4ecd9b8_employeeType,extension_41897758e7564190ac6d4f5ea4ecd9b8_extensionAttribute4,state,mail,jobTitle,mobilePhone,userPrincipalName", { headers: { Authorization: `Bearer ${token}` } })
                .then((_response: Response) => {
                    return this.processUser(_response);
                });;
        }
    }
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    private processUser(response: Response): Promise<any> {
        const status = response.status;
        let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                return result200;
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(<any>null);
    }

    private fetchProxy2 = async (input: RequestInfo, init?: RequestInit) => {
        return fetch(input, init);
    }


    private fetchProxy = async (input: RequestInfo, init?: RequestInit) => {
        if (this._getAccessToken) {
            const token = await this._getAccessToken(loginRequest.scopes);
            init = init || {};
            init.headers = { ...init.headers, Authorization: `Bearer ${token}` };
            init.signal = this._signal;
            return fetch(input, init);
        } else { return Promise.reject("No token getter"); }
    }
}
function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}