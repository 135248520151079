import React, { useEffect } from 'react';
import { VVSAction } from '../generated/ClientApi';
import { ColorButton } from './ColorButton'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../Utils';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useHistory } from 'react-router';
import { loginRequest } from '../authConfig';

export const ActionMenu = ({ setAction }: { setAction: (act: VVSAction) => void }) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    
    const login = () => {
        instance.loginRedirect({ ...loginRequest});
    }
    
    const handleLogout = () => {
        instance.logoutRedirect({postLogoutRedirectUri:"/"});
    }
    return (<div className={classes.root}>
        <br />
        <br />
        <ColorButton variant="contained" color="primary" onClick={() => { setAction(VVSAction.UpdateVaccinationForm) }}>{t('updateVaccinationRecord')}</ColorButton>
        <br />
        <br />
        <ColorButton variant="contained" color="primary" onClick={() => { setAction(VVSAction.UpdateTestingForm) }}>{t('updateTestingRecord')}</ColorButton>
        {isAuthenticated && <><br />
            <br />
            <ColorButton variant="contained" color="primary" onClick={handleLogout}>{t('logOut')}</ColorButton>
        </>}
        {!isAuthenticated && <><br />
            <br />
            <ColorButton variant="contained" color="primary" onClick={() => { login() }}>{t('logIn')}</ColorButton>
        </>}
    </div>)
}