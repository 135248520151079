import React from 'react';
import { AuthUsersHome } from './components/AuthUsersHome';
import { AnonymousHome } from './components/AnonymousHome';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import './custom.css'
import { Landing } from './components/Landing';
import { Route } from 'react-router';
import { Verification } from './components/Verification';


export const App = () => {
    return <>
        <AuthenticatedTemplate>
            <Route path='/verify-vaccination-record' exact={true}>
                <Verification />
            </Route>
            <Route path='/' exact={true}>
                <AuthUsersHome />
            </Route>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Landing />
        </UnauthenticatedTemplate>
    </>
}
