import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "696f1f07-7ccc-43f2-bf2f-3f6379360f30",
        authority: "https://login.windows.net/75db7714-e09e-45d8-b05f-7a658f861edc/",
        redirectUri: process.env.REACT_APP_BASE_URL        
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['api://696f1f07-7ccc-43f2-bf2f-3f6379360f30/user', "openid", "profile", "offline_access", "User.Read"]
};