import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './NavMenu.css';
import AppBar from '@material-ui/core/AppBar';
import { Menu, Toolbar, Typography, Button, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
            paddingLeft:'10px'
        },
        logo: {
            maxWidth: "20%",
            maxHeight:'65px'
        },
        switch: {
            backgroundColor: "#70AD47",
            '&:hover': {
                backgroundColor: green[600],
            },
            marginLeft: 0,
            width: '60px',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        bar: {
            backgroundColor:"#002e6d"
        }
    }),
);

export const NavMenu = (props: {setLocale:(l:'en'|'es')=>void}) => {
    const { t, i18n } = useTranslation('common');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (val: string) => {
        i18n.changeLanguage(val);
        props.setLocale(val as 'en' | 'es');
        setAnchorEl(null);
    };
    return (
        <AppBar className={classes.bar} position="static">
            <Toolbar>
                <img className={classes.logo} src="/images/logo.png"/>
                <Typography variant="h6" className={classes.title}>{t('appTitle')}</Typography>
                <Button variant="contained" className={ classes.switch} color="primary" onClick={handleClick}>{i18n.language.toUpperCase()}</Button>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => { handleClose('en')}}>EN</MenuItem>
                    <MenuItem onClick={() => { handleClose('es') }}>ES</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}
