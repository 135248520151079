import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { ColorButton } from './ColorButton';
import { GreenCheckbox } from './GreenCheckbox';
import { useStyles } from '../Utils';
import { VVSAction, TestingRecord } from '../generated/ClientApi';
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { AppContext } from '../AppContext';
import { FileUploader } from './FileUploader';

export const TestingForm = ({ user, setAction }: { user: any, setAction: (v: VVSAction) => void }) => {
    const { t } = useTranslation('common');
    const [record, setRecord] = useState<TestingRecord | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const classes = useStyles();
    const ctx = useContext(AppContext);
    const load = async () => {
        const data = await ctx.apiMap?.anonymousClient.getEmptyTestingRecord();
        if (data && user) {
            data.employeeId = user.id;
            data.employeeName = user.displayName;
            data.employeeNoStr = user.extension_41897758e7564190ac6d4f5ea4ecd9b8_extensionAttribute1;
        }
        setRecord(data);
    }

    useEffect(() => {
        load();
    }, [])

    const submit = async () => {
        try {
            if (!record?.testedNegative && !record?.testedPositive) {
                throw t('selectTestResult');
            }
            if (!record?.documentUrl) {
                throw t('testPictureRequired');
            }
            if (!record?.supervisor) {
                throw t('supervisorRequired');
            }
            if (!record.employeeName || record.employeeName == '' || !/\w{2,50}\s?\w{2,50}/.test(record.employeeName)) {
                throw t('employeeNameIsRequired');
            }
            if (!record.employeeNoStr || record.employeeNoStr == '') {
                throw t('employeeNoIsRequired');
            }
            if (record?.dateOfTest == "0001-01-01T00:00:00") {
                throw t('testDateRequired');
            }
            await ctx.apiMap?.anonymousClient.updateTestingRecord(record);
            setAction(VVSAction.ShowUpdateMenu);
        } catch (err) {
            setError(err);
        }
    }

    const testedNegativeChanged = (val: boolean) => {
        const temp: TestingRecord = { ...record } as TestingRecord;
        if (temp) {
            if (val === true) {
                temp.testedPositive = false;
            }
            temp.testedNegative = val;
            setRecord(temp);
        }
    }
    const testedPositiveChanged = (val: boolean) => {
        const temp: TestingRecord = { ...record } as TestingRecord;
        if (temp) {
            if (val === true) {
                temp.testedNegative = false;
            }
            temp.testedPositive = val;
            setRecord(temp);
        }
    }

    return (record ?
        <div className={classes.root}>
            <br/>
            <Typography variant="h5" className={classes.formHeader}>{t('COVIDTestValidation')}</Typography>
            <br/>
            <Typography>{t('testValidationDescription')}</Typography>
            <br/>
            <Typography>{t('testValidationText')}</Typography>
            <br/>
            <Typography variant="h6">{t('testConfirmation')}</Typography>
            <FormControlLabel
                control={
                    <GreenCheckbox
                        checked={record.testedNegative}
                        size="medium"
                        onChange={(event: any) => { testedNegativeChanged(event.target.checked) }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
                label={<b className={classes.checkboxLabel}>{t('testedNegative')}</b>}
            />
            <Typography>{t('testedNegativeDescription')}</Typography>
            <FormControlLabel
                control={
                    <GreenCheckbox
                        checked={record.testedPositive}
                        size="medium"
                        onChange={(event: any) => { testedPositiveChanged(event.target.checked) }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
                label={<b className={classes.checkboxLabel}>{t('testedPositive')}</b>}
            />
            <Typography>{t('testedPositiveDescription')}</Typography>
            {(record.testedNegative || record.testedPositive) && <>
                <Typography><b>{t("uploadTest")}</b></Typography>
                <FileUploader data={record.documentUrl} onDataChanged={(d, fn, ft) => { setRecord({ ...record, documentUrl: d, documentName: fn, documentType: ft }) }} />
                <br />
            </>
            }
            <br/>
            <Typography><i>{t('testFormDisclimer')}(<a href="https://vaccinefinder.org/search/">vaccinefinder.org/search/</a>)</i></Typography>
            <br/>
            <TextField
                label={t('dateOfTest')}
                type="date"
                value={record?.dateOfTest}
                onChange={(event) => { setRecord({ ...record, dateOfTest: event.target.value }) }}
                InputLabelProps={{
                    shrink: true,
                }}
            /><br />
            <TextField
                label={t('supervisor')}
                value={record.supervisor}
                onChange={(event) => { setRecord({ ...record, supervisor: event.target.value }) }}
            />
            <TextField value={record.employeeName} label={t('employeeName')} disabled={user} />
            <TextField value={record.employeeNoStr} label={t('employeeNumber')} disabled={ user } />
            <br/>
            <div>
                <ColorButton variant="contained" color="primary" onClick={submit}>{t("submit")}</ColorButton>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button variant="contained" color="secondary" onClick={() => setAction(VVSAction.ShowUpdateMenu)}>{t("close")}</Button>
            </div>
            <Dialog
                open={error != undefined}
                onClose={() => setError(undefined)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('errorTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{error}</DialogContentText>
                </DialogContent>
            </Dialog>
        </div> : <CircularProgress />
    );
}
