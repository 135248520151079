import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
export const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#8CC63F",
        '&:hover': {
            backgroundColor: green[600],
        },
    },
}))(Button);